<template>
  <div class="searchCategory main-content" style="border: none">
    <div style="margin-bottom: 10px;">
      <el-breadcrumb v-if="isLike" separator-class="el-icon-arrow-right" style="display: flex; align-items: center;">
        <el-breadcrumb-item>全部结果</el-breadcrumb-item>
        <el-breadcrumb-item>
          <el-tag closable size="mini" @close="handleClose">相似商品</el-tag>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="border: 0.2px solid rgb(225 219 219);background: #fff;">
      <!--      产地---精选商品显示-->
      <div v-if="1 == type"  class="searchCategory" style="display: flex; justify-content: space-between;">
        <div
          style="align-items: flex-start; text-align: center;width: 88px;min-height: 36px; border-right: 1px dashed #ddd;line-height: 35px;">
          产地</div>
        <div ref="firstCategory" :class="['thirdCategory', { open: this.originOpen }]"
          style="width:1090px;margin-left: 87px;min-height: 36px; margin-top: -37px;border-left: 1px dashed #ddd;">
          <a-button v-for="(item, index) in originInfo" :key="index"
            :type="countyIds.indexOf(item.id) >= 0 || (countyIds.length === 0 && index === 0) ? 'danger' : ''"
            class="tabButton" style="padding-left: 20px;font-size: 12px; cursor: pointer;" @click="onOrigin(item, index)">
            <label
              :class="countyIds.indexOf(item.id) >= 0 || (countyIds.length === 0 && index === 0) && item.country != '全部' ? 'correct' : ''">
              {{ item.country }}
            </label>
          </a-button>
          <div class="CategoryFlag" @click="originOpen = !originOpen">{{ originOpen ? '收起' : '更多' }}</div>
        </div>
      </div>


      <!-- 一级分类   -->
      <div class="searchCategory" style="display: flex; justify-content: space-between;">
        <div
          style="align-items: flex-start;border-right: 1px dashed #ddd; text-align: center;width: 88px;line-height: 25px;">
          类目</div>
        <div ref="firstCategory" :class="['firstCategory', { open: this.firstCategoryOpen }]"
          style="width:1090px;margin-left: 87px;margin-top: -25px;border-left: 1px dashed #ddd;">
          <a-button v-for="(category, index) in Category" :key="index"
            :type="category.id == firstCategoryId ? 'danger' : ''" class="tabButton"
            @click="handleFirstCategory(category)">{{ category.name }}</a-button>
          <div class="CategoryFlag" @click="firstCategoryOpen = !firstCategoryOpen">{{ firstCategoryOpen ? '收起' : '更多' }}
          </div>
        </div>
      </div>
      <!-- !!!!!!!!!!!  选择完一级分类二级才出现   -->
      <!-- 二级分类   -->
      <div v-if="showOne && secondCategoryList.length > 1" class="searchCategory">
        <div v-if="secondCategoryList.length === 0" style="margin-left: 40px;margin-top: 10px">请选择一级分类</div>
        <div v-else ref="secondCategory" :class="['secondCategory', { open: this.secondCategoryOpen }]">
          <span class="searchTitle"
            style="align-items: flex-start;border-right: 1px dashed #ddd;line-height: 35px; border-bottom: 1px dashed #ddd;text-align: center;width: 88px">分类</span>
          <a-button v-for="(category, index) in secondCategoryList" :key="index"
            :type="category.id == secondCategoryId ? 'danger' : ''" class="tabButton"
            @click="handleSecondCategory(category)">{{ category.name }}</a-button>
        </div>
      </div>
      <!-- 三级分类   -->
      <div v-if="showTwo" class="searchCategory">
        <div v-if="thirdCategoryList.length === 0" style="margin-left: 40px;margin-top: 10px">请选择二级分类</div>
        <div v-else ref="thirdCategory" :class="['thirdCategory', { open: this.thirdCategoryOpen }]">
          <span class="searchTitle"
            style="align-items: flex-start;border-right: 1px dashed #ddd;line-height: 35px; border-bottom: 1px dashed #ddd;text-align: center;width: 88px">下级目录</span>
          <a-button v-for="(category, index) in thirdCategoryList" :key="index"
            :type="category.id == thirdCategoryId ? 'danger' : ''" class="tabButton"
            @click="handleThirdCategory(category)">{{ category.name }}</a-button>
        </div>
      </div>
      <!--      JD/1688 标签-->
<!--      <div v-if="labeListBoole" class="searchCategory" style="display: flex; justify-content: space-between;">-->
<!--        <div-->
<!--          style="align-items: flex-start; text-align: center;width: 88px;min-height: 36px; border-right: 1px dashed #ddd;line-height: 35px;">-->
<!--          推荐标签</div>-->
<!--        <div ref="firstCategory" :class="['thirdCategory', { open: this.thirdCategorysOpen }]"-->
<!--             style="width:1090px;margin-left: 87px;min-height: 36px; margin-top: -37px;border-left: 1px dashed #ddd;">-->
<!--          <a-button-->
<!--            v-for="(item, index) in labeList"-->
<!--            :key="index"-->
<!--            :type="item.id === labes ? 'danger' : ''"-->
<!--            class="tabButton"-->
<!--            @click="onLabeList(item)"-->
<!--          >{{ item.isRecommend == 1 ? item.label : '' }}</a-button>-->
<!--        </div>-->
<!--      </div>-->
      <!--      品牌-->
      <div v-if="1 == type" class="searchCategory" style="display: flex; justify-content: space-between;">
        <div
          style="align-items: flex-start; text-align: center;width: 88px;min-height: 36px; border-right: 1px dashed #ddd;line-height: 35px;">
          品牌</div>
        <div ref="firstCategory" :class="['thirdCategory', { open: this.thirdCategorysOpen }]"
          style="width:1090px;margin-left: 87px;min-height: 36px; margin-top: -37px;border-left: 1px dashed #ddd;">
          <a-button v-for="(item, index) in brandInfo" :key="index" :type="item.id === activeBrand ? 'danger' : ''"
            class="tabButton" @click="onBrand(item)">{{ item.name }}</a-button>
          <div v-if="thirdCategorysOpen" style="display: flex; align-items: center;margin-left: 15px;">
            <el-input v-model="tabName" placeholder="请输入品牌" size="small"></el-input>
            <el-button size="small" style="margin-left: 15px;" type="primary" @click="tabButtonClick">确认 </el-button>
          </div>
          <div class="CategoryFlag" @click="thirdCategorysOpen = !thirdCategorysOpen">{{ thirdCategorysOpen ? '收起' : '更多'
          }}</div>
        </div>
      </div>
    </div>
    <!-- 新增一个搜索条件 -->
    <div class="navc">
      <div>
        <el-radio-group v-model="radio1" fill="#ff7300" size="medium" @change="sortType">
          <el-radio-button label="1">
            <div style="display: flex;align-items: center;margin-left: 10px;">
              <div>综合</div>
            </div>
          </el-radio-button>
          <el-radio-button label="2">
            <div>销量
              <div style="position: absolute;right: 3px;top: 10px;">
                <div class="el-icon-caret-bottom" style="position: absolute; right: 0;top: 0;color: #999999;"></div>
              </div>
            </div>
          </el-radio-button>
          <el-radio-button label="3">
            <div style="position: relative;">
              价格
              <div style="position: absolute;right: -18px;top: 8px;">
                <div style="width: 30px;background: red;position: relative; ">
                  <div :style="sortT == 0 ? 'color: #666666;' : 'color: #d9d9d9;'" class="el-icon-caret-top"
                    style="position: absolute;  right: 0;bottom: 0;" @click.stop="sortTClick('1')"></div>
                  <div :style="sortT == 1 ? 'color: #666666;' : 'color: #d9d9d9;'" class="el-icon-caret-bottom"
                    style="position: absolute; right: 0;top: 0;" @click.stop="sortTClick('0')"></div>
                </div>
              </div>
            </div>
          </el-radio-button>
        </el-radio-group>
      </div>
      <div style="margin-left: 50px;">
        <el-input v-model.trim="price1" class="int" placeholder="￥" size="small"
          @input="limitNumMinPrice($event)"></el-input>
        <span style="margin:0 10px;">-</span>
        <el-input v-model.trim="price2" class="int" placeholder="￥" size="small"
          @input="limitNumMaxPrice($event)"></el-input>
        <el-button plain size="small" style="margin: 0 20px;" type="primary" @click="save">确定</el-button>
      </div>
    </div>

    <!-- 缺省页 -->
    <div v-show="loadIng" style="display: flex;justify-content: center;align-items: center;height: 335px;">
      <img src="/img/lo.gif" />
    </div>
    <div
      v-if="goodslength == 0 && loadIng == false"
      style="display: flex;justify-content: center;align-items: center;height: 455px;flex-direction: column;padding-top: 50px;">
      <div style="text-align: center">
        <img src="/img/cart.png" style="width: 150px;" />
      </div>
      <div style="margin-top: 30px;">
        <h5 style="text-align: center;">没找到相关的商品</h5>
        <div style="text-align: center;">
          您可以：缩短或修改您的搜索词，重新搜索
        </div>
        <!--        <div>1.缩短或修改您的搜索词，重新搜索</div>-->
      </div>
    </div>
    <!--    首次加载数据增加高度-->
    <div v-show="goodslength !== 0" :style="goods.length == 0 ? 'height: 535px;' : ''"></div>
    <!--  商品显示  -->
    <div v-if="goods.length > 0 && loadIng == false" :class="csdn ? 'csbg' : ''" style="margin-bottom: 50px;">
      <listCard :list="goods" :size="75" isIndex="1"></listCard>
    </div>

    <!-- 分页器   -->
    <div v-show="goods.length > 0 && loadIng == false" class="flex-row" style="flex-direction: row-reverse;padding: 10px">
      <a-pagination v-model="queryData.current" :page-size="queryData.size" :page-size-options="pageSizeOptions"
        :total="total" show-size-changer @change="onChangePages" @showSizeChange="onShowSizeChange">
      </a-pagination>
      <div style="margin-right: 50px;cursor: default">共 {{ total }} 条数据</div>
    </div>
    <div v-show="isFixed" class="go-top" style="width:40px;height:40px;border-radius:50%;" @click="goTop">
      <div
        style="{ height: 100%; width: 100%;border-radius:50%;background-color: #f2f5f6; box-shadow: 0 0 6px rgba(0,0,0, .12); text-align: center; line-height: 40px; color: #1989fa;}">
        <el-icon class="el-icon-caret-top" style="font-size:20px"></el-icon>
      </div>
    </div>

  </div>
</template>

<script>
import { limitNumTool, editUrl } from '@/utils/utils'
import Ellipsis from '@/components/Ellipsis/Ellipsis'
import { getGoodsCategory, getDynamicBrand } from '@/api/Category'
import find from 'lodash.find'
import cloneDeep from 'lodash.clonedeep'
import { favoriteProduct, getProductPage, allLocality, allBrandlity } from '@/api/Product'
import { mapGetters } from 'vuex'
import { addSelectGoods, appInteriorBrand, supplyLabelList } from '@/api/SelectGoods'
import listCard from '@/components/listCard/card'
import { forEach } from 'jszip'
import { set } from 'nprogress'
import { getFindSimilarity } from '@/api/Product'

export default {
  name: 'SearchCategory',
  components: { Ellipsis, listCard },
  data() {
    return {
      labes: '',
      labeListBoole: false,
      labeList: [],
      likeData: [], // 找相似列表
      isLike: false,
      scrollTop: 0,
      isFixed: false, //固定顶部导航
      activeOrigin: 0,
      dynamicTags: [],
      activeBrand: '',
      brandInfo: [],
      originInfo: [],
      sortT: 3,
      tabName: '',//搜索品牌字段
      pageSizeOptions: ['30', '50', '75'],
      total: 1,
      categoryBtns: {
        tradeType: [
          { name: '国内贸易', value: '1' },
          { name: '完税进口', value: '2' },
          { name: '香港直邮', value: '3' },
          { name: '海外直邮', value: '4' }
        ]
      },
      loadIng: false,
      Category: [],
      firstCategoryId: '', // 选择的一级分类 ID
      secondCategoryList: [], // 根据一级分类 动态变换的二级分类表
      thirdCategoryList: [{ id: 0, name: '全部' }], // 根据一级分类 动态变换的二级分类表
      secondCategoryId: '', // 选择的二级分类
      thirdCategoryId: '', // 选择的三级分类
      tradeType: '', // 选择贸易类型
      countyIds: this.$route.query.countyIds ? [this.$route.query.countyIds] : [], // 选择产地（考虑在首页选择国家进入搜索页面高亮显示）
      interiorBrandId: '', // 选择品牌
      firstCategoryOpen: true, //控制类目展开收齐
      thirdCategorysOpen: false, //控制品牌展开收齐
      originOpen: false,    // 控制产地展开收齐
      secondCategoryOpen: false,
      thirdCategoryOpen: false,
      goods: [], // 查询到的商品数据
      queryData: {
        searchName: this.$route.params.searchName,
        current: 1,
        size: 75,
        pages: 1, // 数据页数
        minPrice: this.price1,
        maxPrice: this.price2,
      },
      showOne: false,
      showTwo: false,
      isGo: false,
      radioValue: 0,
      radio1: '1',
      price1: '', // 价格区间
      price2: '', // 价格区间
      csdn: false,
      radioList: [{ id: 0, name: '全部类型' },
      { id: 7, name: '保税直供' },
      { id: 2, name: '完税进口' },
      { id: 1, name: '国内贸易' },
      { id: 3, name: '香港直邮' },
      { id: 4, name: '海外直邮' }],
      categoryID: '',
      goodslength: 1,
      firstCate: false,
      random: '',
      type: undefined
    }
  },
  created() {
    this.loadIng = true
    if (this.$route.query.type) this.type = this.$route.query.type || 1
    // 获取当前随机分页
    if (this.secondCategoryList.length < 1) this.secondCategoryList.unshift({ id: 0, name: '全部' })

    if (this.$route.query.randomStype && this.$route.query.like) {
      this.random = this.$route.query.randomStype
      this.initLike(this.$route.query.like)
    }

    // 搜索事件触发接口调用
    window.a = () => {
      this.searchCategory()
    }

    if (this.$route.query.firstCategory != 0) this.showOne = true
    if (this.$route.query.firstCategory && this.$route.query.secondCategory) this.showTwo = true
    this.radioValue = Number(this.$route.query.radio) || 0

    this.csdn = true

    /* 触发获取1688/京东的标签 */
    // this.initLabelList()

    /* 触发品牌 */
    if (this.$route.query.type == 1) this.pinpaiFn()

    getGoodsCategory({type: this.type}).then((res) => {
      this.Category = res.data
      this.csdn = false
      res.data.forEach((item, index) => {
        if (item.id == this.$route.query.firstCategory) {
          this.secondCategoryList = item.childrens
        }
      })
      this.Category.unshift({ id: 0, name: '全部' })
      if (this.$route.query.searchName) {
        this.queryData.searchName = this.$route.query.searchName
        this.searchCategory()
      } else {
        this.activeBrand = this.$route.query.interiorBrandId || ''
        this.firstCategoryId = this.$route.query.firstCategory || ''
        this.secondCategoryId = this.$route.query.secondCategory || ''
        this.thirdCategoryId = this.$route.query.thirdCategory || ''
        this.tradeType = this.$route.query.tradeType || null
        // this.secondCategoryId ? this.showOne = true : this.showOne = false
        // this.thirdCategoryId ? this.showTwo = true : this.showTwo = false
        this.firstCategoryOpen = true
        this.searchCategory()
      }
      if (this.$route.query.type == 1) this.getAllBrandlity()
    })
    if (this.$route.query.type == 1) this.guojaiFn()

  },
  watch: {
    $route(n, o) {
      if (n.query.firstCategory == 0) {
        // this.$router.push({ path: '/search', query: { firstCategory: 0 } })
        this.$router.go(0)
      } else {
        this.$router.go(0)
      }
    },
    '$store.state.names'(n, o) {
      if (n == '所有商品') {
        this.$router.go(0)
      }
    },
    /**
     * 动态变更二级分类
     */
    firstCategoryId(newValue, oldValue) {
      if (newValue == '' && newValue !== oldValue) {
        this.showOne = false
        this.showTwo = false
        this.secondCategoryList = []
      }
      if (newValue !== oldValue && newValue !== undefined && newValue !== '') {
        const _findCategory = find(this.Category, (item) => {
          return item.id == newValue
        })
        if (_findCategory != undefined) {
          if (_findCategory.childrens != undefined) {
            this.secondCategoryList = cloneDeep(_findCategory.childrens)
            this.secondCategoryList.unshift({ id: '', name: '全部' })
          } else {
            this.showOne = false
          }
        }

      }
    },
    secondCategoryId(newValue, oldValue) {
      const _findCategory = find(this.secondCategoryList, (item) => {
        return item.id == newValue
      })
      if (newValue == '' && newValue !== oldValue) {
        this.thirdCategoryList = [{ id: '', name: '全部' }]
        this.showTwo = false
      }
      if (newValue !== oldValue && newValue !== undefined && newValue !== '') {
        this.thirdCategoryList = cloneDeep(_findCategory.childrens) || []
        this.thirdCategoryList.unshift({ id: '', name: '全部' })
      }
    },

  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
  },
  mounted() {
    //滑动监听-显示返回顶部
    window.addEventListener('scroll', () => {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // 当垂直滚动距离大于 2900  返回true，则this.isFixed = true，添加is_fixed类名
      this.isFixed = this.scrollTop > 2900
    })

  },
  methods: {
    // 品牌
    pinpaiFn() {
      appInteriorBrand().then(res => {
        this.brandInfo = res.data
      })
    },
    // 国家产地
    guojaiFn() {
      // 国家产地
      allLocality().then(res => {
        this.originInfo.push(
          {
            country: '全部',
            createTime: '',
            id: 0,
            status: 1,
            updateTime: null
          }
        )
        this.originInfo = this.originInfo.concat(res.data)

      }).catch(err => {
        console.error(err)
      })
    },
    /* 获取JD/1688商品标签 */
    initLabelList () {
      let obj = [
        {
          createId: '',
          createTime: '',
          id: '',
          isRecommend: 1,
          label: '精选商品',
          updateTime: ''
        }
      ]
      supplyLabelList().then(res => {
        console.log('商品标签成功', res)
        this.labeList.push(obj)
        if (res.ok) {
          obj = obj.concat(res.data)
          this.labeList = obj
          this.labeList.forEach(item => {
            if (this.labeList.length) {
              if (item.id) this.labeListBoole = item.isRecommend == 0 ? false : true
            } else {
              this.labeListBoole = true
            }
          })
        }
      }).catch(err => {
        console.error('接口错误信息', err)
      })
    },
    // 找相似
    initLike(id) {
      getFindSimilarity({ id, random: this.random, current: this.queryData.current, size: this.queryData.size }).then(res => {
        console.log('找相似', res)
        this.loadIng = false
        this.goods = res.data.records
        this.isLike = true
        this.goodslength = res.data.records.length === 0 ? 0 : res.data.records.length
      })
    },
    limitNumMinPrice(value) {
      this.price1 = limitNumTool(value, 9)
    },
    limitNumMaxPrice(value) {
      this.price2 = limitNumTool(value, 9)
    },
    sortTClick(i) {
      console.log('触发')
      this.sortT = i
      var queryData = {
        current: this.queryData.current,
        size: this.queryData.size,
        sortType: this.radio1,
        searchName: this.queryData.searchName ? this.queryData.searchName : '',
        pages: this.queryData.pages,
        minPrice: this.price1,
        maxPrice: this.price2,
        orderAsc: this.sortT,//正序倒序,
        random: ['2', '3'].includes(this.radio1) ? null : this.random
      }
      this.csdn = true
      this.queryData = queryData
      this.searchCategory()
    },
    // 确认搜索品牌
    tabButtonClick() {

      this.getAllBrandlity()
    },
    getAllBrandlity() {
      getDynamicBrand({
        cateId: this.firstCategoryId,
        countyIds: this.countyIds.join(','),
        tradeType: this.$route.query.tradeType, //动态查询品牌得类型字段：
        name: this.tabName,
      }).then(res => {

        let tps = [{
          createTime: '',
          enable: 1,
          id: '',
          name: '全部',
          updateTime: '',
        }]
        tps = tps.concat(res.data)
        this.brandInfo = this.brandInfo.concat(res.data)
        if (this.firstCategoryId != 0) this.brandInfo = tps

      })
    },
    // 排序
    sortType(e) {
      console.log('排序',e)
      this.sortT = 3
      if (e == 3) {
        this.sortTClick('1')
        return
      }
      var queryData = {
        current: this.queryData.current,
        size: this.queryData.size,
        sortType: this.radio1,
        searchName: this.queryData.searchName ? this.queryData.searchName : '',
        pages: this.queryData.pages,
        minPrice: this.price1,
        maxPrice: this.price2,
      }
      this.csdn = true
      this.queryData = queryData
      this.searchCategory()

    },
    goTop() { //点击返回顶部
      const that = this
      let timer = setInterval(() => { //加计时器是为了页面过渡顺滑
        let ispeed = Math.floor(-that.scrollTop / 6)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 15)
    },
    radioChang(e) {
      console.log('ssss', e)
      this.tradeType = e
      var queryData = {
        tradeType: this.tradeType,
        current: this.queryData.current,
        size: this.queryData.size,
        pages: this.queryData.pages,
        minPrice: this.price1,
        maxPrice: this.price2,
      }
      getProductPage(queryData).then((res) => {
        this.goods = res.data.records
        this.total = res.data.total
        if (res.data.records.length === 0) {
          this.goodslength = 0
        } else {
          this.goodslength = res.data.records.length
        }
      })
    },
    // 搜索价格区间
    save() {
      let vm = this
      const queryData = {
        current: 1,
        size: this.queryData.size,
        sortType: this.radio1,
        pages: this.queryData.pages,
        minPrice: this.price1,
        maxPrice: this.price2,
        searchName: this.queryData.searchName
      }
      this.csdn = true
      this.loadIng = true
      this.queryData = queryData
      this.searchCategory()
    },
    handleClose() {
      window.history.replaceState('', '', editUrl(this.$route.fullPath, ['like']))   /* 把路由携带的like字段去掉 */
      this.$route.query.like = null
      if (!this.$route.query.like) delete this.$route.query.like
      this.$nextTick(() => {
        this.searchCategory()
        this.isLike = false
      })
    },

    handleAddGoods(item) {
      const that = this
      const ids = [item.id ? item.id : null]
      if (item.isPuint == 1 || that.isGo) {
        this.$router.push('/user/shopLibrary')
      } else {


        this.$confirm('你确定要将这些商品一键入库吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        }).then(() => {
          addSelectGoods(ids).then((res) => {
            if (res.code === 0) {
              that.$message.success('入库成功')
              that.isGo = true
              this.getProductPage(this.queryData)
            } else if (res.code === -1) {
              that.$message.error(res.msg)
            }
          })
        }).catch(() => {

        })
      }
    },
    // 产地方法
    onOrigin(item, index) {

      this.loadIng = true
      this.activeBrand = ''

      // 如果不在数组内
      if (this.countyIds.indexOf(item.id) === -1 && item.id !== 0) {
        this.countyIds.push(item.id)
      } else if (item.id !== 0 && this.countyIds.indexOf(item.id) >= 0) {
        // 反选
        this.countyIds.splice(this.countyIds.indexOf(item.id), 1)
      } else if (item.id === 0) {
        this.countyIds = []
      }
      this.getAllBrandlity(this.firstCategoryId, this.countyIds)

      this.price1 = ''
      this.price2 = ''
      this.queryData.current = 1
      this.queryData.searchName = ''
      if (this.$route.query.like) this.initLike(this.$route.query.like)
      if (window.rout) window.rout()

      this.searchCategory()
    },
    // 标签fn
    onLabeList(item) {
      console.log('标签', item)
      this.loadIng = true
      this.labes = item.id
      this.searchCategory()
    },
    // 品牌方法
    onBrand(item) {
      // console.log('品牌',item)
      this.dynamicTags[0] = item.name
      this.loadIng = true
      this.activeBrand = item.id
      this.price1 = ''
      this.price2 = ''
      this.queryData.current = 1
      this.queryData.searchName = ''
      if (this.$route.query.like) this.initLike(this.$route.query.like)
      if (window.rout) window.rout()
      this.searchCategory()
    },
    // 分页器
    onChangePages(page, pageSize) {

      this.queryData.current = page
      this.queryData.size = pageSize
      this.queryData.maxPrice = this.price2
      this.queryData.minPrice = this.price1
      this.csdn = true
      // this.cleanData()
      setTimeout(() => {
        this.goTop()
        this.csdn = false
      }, 500)
      this.searchCategory()
    },
    onShowSizeChange(current, pageSize) {

      this.queryData.searchName = this.$route.query.searchName
      this.queryData.current = 1
      this.queryData.size = pageSize
      this.searchCategory()
    },
    handleHeart(item, index) {
      if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
        favoriteProduct(item.id).then(() => {
          item.isFavorite === 1 ? item.isFavorite = 0 : item.isFavorite = 1
        })
      }
    },
    /**
     * 清空数据
     */
    cleanData() {
      this.firstCategoryId = ''
      this.secondCategoryId = ''
      this.thirdCategoryId = ''
      this.categoryID = ''
      this.activeOrigin = ''
    },
    /**
     * 标签搜索商品
     * @param more 是否加载更多
     */
    searchCategory(more = false) {
      var that = this

      // if (this.tradeType) {  }
      const queryData = Object.assign({
        firstCategoryId: that.firstCategoryId,
        secondCategoryId: that.secondCategoryId,
        thirdCategoryId: that.thirdCategoryId,
        tradeType: this.tradeType || this.$route.query.radio,
        countyIds: that.countyIds.join(','),
        interiorBrandId: this.activeBrand,
        sortType: that.radio1,
        minPrice: that.price1,
        maxPrice: that.price2,
        orderAsc: that.sortT,//正序倒序
        random: ['2', '3'].includes(this.radio1) ? null : that.random,  // 销量和价格不需要传随机字段
        labelId: this.labes ? this.labes : null,
        productType: this.type || 1
      }, that.queryData)

      Object.keys(queryData).forEach((item) => {
        if (queryData[item] === '' || queryData[item] === undefined || queryData[item] === null) { delete queryData[item] }
      })
      // this.loadIng = true
      // 如果加载更多则当前页数++, 否则页数等于1
      // more ? this.queryData.current++ : this.queryData.current = 1
      if (this.$route.query.like) return         /* 根据找相似进入搜索页面不调以下接口 */
      getProductPage(queryData).then((res) => {
        this.csdn = false
        this.goods = res.data.records
        this.total = res.data.total
        this.loadIng = false
        if (res.data.records.length === 0) {
          this.goodslength = 0
        } else {
          this.goodslength = res.data.records.length
        }
      }).catch(err => {
        this.loadIng = false
        this.csdn = false
      })
      // getProductPage(queryData).then((res) => {
      //   // 如果加载更多则向后追加, 否则重新赋值
      //   more ? this.goods = [...this.goods, ...res.data.records]
      //     : this.goods = res.data.records
      //   this.queryData.pages = res.data.pages
      // })
    },
    /**
     * 点击一级分类
     * @param category
     */
    handleFirstCategory(category) {
      this.categoryID = category.id
      this.loadIng = true
      this.activeBrand = ''
      this.dynamicTags[0] = category.name
      // 如果与上次值相同终止方法
      if (category.id === this.firstCategoryId) {

        this.firstCategoryId = category.id
        this.secondCategoryList = [{ id: '', name: '全部' }]
      } else {
        if (this.secondCategoryList.length > 0) {
          this.showOne = true
          // this.thirdCategoryId = ''
        }
        this.categoryID = ''
        this.firstCategoryId = category.id
        this.secondCategoryId = ''
        this.thirdCategoryId = ''
        this.getAllBrandlity()
      }
      // this.secondCategoryId = ''
      if (this.$route.query.like) this.initLike(this.$route.query.like)
      this.price1 = ''
      this.price2 = ''
      this.queryData.current = 1
      this.queryData.searchName = ''
      if (window.rout) window.rout()
      this.searchCategory()
    },
    /**
     * 点击二级分类
     * @param category
     */
    handleSecondCategory(category) {
      this.categoryID = category.id
      this.loadIng = true
      this.activeBrand = ''
      this.activeOrigin = ''
      this.secondCategoryId = category.id
      this.getAllBrandlity(category, this.activeOrigin)
      this.dynamicTags[this.dynamicTags.length] = category.name
      if (category.childrens == null && this.thirdCategoryList.length === 1) {
        this.showTwo = false
      } else {
        this.showTwo = true
        this.thirdCategoryId = ''

      }
      // 如果与上次值相同终止方法
      // category.id === this.secondCategoryId ? this.secondCategoryId = '' : this.secondCategoryId = category.id
      this.price1 = ''
      this.price2 = ''
      this.queryData.current = 1
      this.queryData.searchName = ''
      if (window.rout) window.rout()
      this.searchCategory()
    },
    /**
     * 点击三级分类
     * @param category
     */
    handleThirdCategory(category) {
      this.categoryID = category.id
      this.loadIng = true
      this.activeBrand = ''
      // 如果与上次值相同终止方法
      this.activeOrigin = ''
      this.getAllBrandlity(category, this.activeOrigin)
      this.dynamicTags[this.dynamicTags.length] = category.name
      this.thirdCategoryId = category.id
      this.price1 = ''
      this.price2 = ''
      this.queryData.current = 1
      this.queryData.searchName = ''
      if (window.rout) window.rout()
      this.searchCategory()
    },
    /**
     * 点击搜索
     * @param value
     */
    onSearch(value) {
      this.firstCategoryId = 1
      this.secondCategoryId = ''
      this.thirdCategoryId = ''
      this.searchCategory()
    },
  },
}
</script>
<style lang="scss" scoped>
.csbg {
  width: 100%;
  height: auto;
  background: rgb(223, 221, 221);
  opacity: 0.3;
  // position: fixed;
  z-index: 100;
}

.go-top {
  bottom: 50px;
  right: 50px;
  position: fixed;
  cursor: pointer;
  z-index: 9999;
  color: #000;
}

.navc {
  background: #fff;
  border: 1px solid #e1e1e1;
  margin-top: 10px;
  text-align: left;
  display: flex;
  align-items: center;
}

.int {
  width: 220px;
}

.el-tag+.el-tag {
  margin-left: 5px;
}

.tp {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.hover {
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 300%);
  width: 100%;
  height: 50px;
  top: 240px;
  left: 0;
}

.bgj {
  display: inline-block;
  position: relative;
  background-color: #2ac845;
}

.correct:before {
  content: '\2714';
  color: #ff4400;
}

.hover {
  opacity: 0
}

.goodsCard:hover {
  position: relative;

  .hover {
    opacity: 1;
    transition: all .5s;
    display: block;
  }
}

.goodsCard:nth-child(6n+6) {
  margin-right: 0;
}

.price {
  color: red;
  font-size: 14px;
  text-align: left;
}

.goods {
  // padding: 20px;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  //display: grid;
  //grid-template-columns: repeat(auto-fill, 25%);
  justify-content: left;

  .goodsCard {
    // margin: 10px 20px;
    margin-right: 15px;
    border-radius: 5px;
  }

  .goodsCard:hover {
    cursor: pointer;
    box-shadow: 0px 1px 4px 5px rgb(235, 229, 232);
  }

  .heart {
    font-size: 22px;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .ant-card-meta-title {
    margin-bottom: 0;
  }
}
</style>

<style lang="scss" scoped>
.searchCategory {
  display: flex;
  //padding: 0 20px;
  flex-direction: column;
  position: relative;
  //line-height: 36px;
  border-bottom: 1px dashed #ddd;

  .searchTitle {
    //font-size: 12px;
    //font-weight: 600;
    align-self: center;
  }

  .firstCategory,
  .secondCategory,
  .thirdCategory,
  .sourceProductType {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
    padding-right: 30px;
    height: 36px;

    &.open {
      height: auto;
      transition: all 0.3s;
    }
  }

  .CategoryFlag {
    position: absolute;
    right: 20px;
    font-size: 12px;
    cursor: pointer;
  }
}

.firstCategory,
.secondCategory,
.thirdCategory,
.sourceProductType {

  .tabButton {
    color: #0a0d10;
    margin: 5px 0;
    // border-color: transparent;
    border-radius: 0;
    font-weight: 400;
    font-size: 12px;
    box-shadow: unset;
    height: 28px;

    &:after {
      box-shadow: 0 0 0 0 rgb(232, 155, 79);
    }

    &:hover {
      // background: transparent;
      color: rgb(232, 155, 79);
      box-shadow: 0 0 12px rgb(0 0 0 / 11%)
    }
  }

  .tabButton.ant-btn-danger {
    background: transparent;
    color: #ff4400;
    font-weight: 600;
    //box-shadow: 0 0 12px rgb(0 0 0 / 11%)

  }

}

.tabButton {
  background: none;
}

button {
  border: none;
  // background: none;
}

.tabNav {
  height: 52px;
  background: #f2f2f2;
  display: flex;
  align-items: center;

  .searchCateBtn {
    width: 172px;
    height: 40px;
    margin: 0 10px;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background: transparent;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .dynamicBtn {
    background: #fff;
    color: #00C48C;
  }

  .tabButton.ant-btn-danger {
    background: #fff;
    color: #00C48C;
  }

}

.tabButton .ant-btn-danger :nth-child(6n+6) {
  margin-left: 100px;
}
</style>
