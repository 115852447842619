<template>
  <div>
    <iphone-preview v-model="showPreview" :scroll="true">
      <a-spin :spinning="previewLoading">
        <div style="max-width: 100%;padding: 1px">
          <img :src="previewData.imgUrl" width="100%">
          <div class="p-10">
            <Ellipsis :length="100" style="font-size: 16px;font-weight: 600">{{ previewData.name }}</Ellipsis>
          </div>
          <div class="px-10 pb-10" style="color: #ff4723;font-size: 22px" v-if="Object.keys(userInfo).length > 0">
            {{ '￥' + previewData.tradePrice }}
          </div>
          <h6 class="text-danger text-md" v-if="Object.keys(userInfo).length === 0">价格登录可见</h6>
          <div class="previewContent" v-html="previewData.detailHtml">
          </div>
        </div>
      </a-spin>
    </iphone-preview>

    
    <SearchCategory @preview="preview"></SearchCategory>
  </div>
</template>

<script>
import IphonePreview from '@/components/iphonePreview/iphonePreview'
import Ellipsis from '@/components/Ellipsis/Ellipsis'
import { userNavOptions } from '@/views/User/userNavOptions'
import DefaultNavigation from '@/components/defaultNavigation'
import SearchCategory from '@/views/Search/SearchCategory'
import { getProductObj } from '@/api/Product'
import { mapGetters } from 'vuex'
import HomeGoodsCategory from '@/views/Home/HomeGoodsCategory/HomeGoodsCategory'

export default {
  name: 'Search',
  components: { SearchCategory, DefaultNavigation, IphonePreview, Ellipsis, HomeGoodsCategory },
  created() {
    this.firstCategoryId = this.$route.query.firstCategory
    this.secondCategoryId = this.$route.query.secondCategory
  },
  data() {
    return {
      showPreview: false,
      previewLoading: false,
      userNavOptions,
      firstCategoryId: '',
      previewData: {},
      secondCategoryId: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    preview(id) {
      this.showPreview = true
      this.previewLoading = true
      getProductObj(id).then(res => {
        this.previewData = res.data
        this.previewLoading = false
      })
    }
  },
}
</script>

<style scoped lang='scss'>
.xiang {
  width: 1200px;
  cursor: pointer;
  border: 1px solid rgb(225, 219, 219);
  background: #fff;
  height: 180px;
  margin: 0 auto;
  margin-top: 10px;
}
</style>
